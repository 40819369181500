.vision-mission {
  background: #704590;
  color: $white;
  h2 {
    font-size: 70px;
    color: #f2bb2e;
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 30px;
    .jpn & {
      font-family: "Noto Serif JP";
    }

    @media(max-width: 1199px){
      font-size:28px;
    }
    @media(max-width: 767px){
      font-size:18px;
    }
  }
  h4{
    font-size:24px;
    font-weight: normal;
    @include breakpoint(small only){
      font-size:18px;
    }
    strong{
      font-weight:bold;
    }
    margin-bottom: 30px;
    .jpn & {
      font-family: "Noto Serif JP";
    }
  }
  .vision,
  .mission {
    padding: 130px 100px;
    @include breakpoint(medium down) {
      padding: 60px 40px;
    }
  }

  .vision {
    position: relative;
    margin: auto;

    &__cols {
      display: flex;
      gap: 40px;
      align-items: center;
      position: relative;
      z-index: 1;
      @include breakpoint(medium down) {
        flex-direction: column;
        gap: 20px;
      }
    }
    &__content {
      max-width: 630px;
      .jpn &{
        @include breakpoint(medium){
          padding-right:35px;
        }
      }
    }

    &__col{
      &-text{
        flex:none;
      }
    }

    &__vector {
      position: absolute;
      top: 100px;
      right: 0;
    }
  }

  .mission {
    position: relative;
    &__cols {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      @include breakpoint(medium down) {
        flex-direction: column;
        gap: 0;
      }
    }

    &__col {
      &:first-child {
        @include breakpoint(medium down) {
          order: 2;
        }
      }
      &:last-child {
        @include breakpoint(medium down) {
          order: 1;
        }
      }
    }
    &__content {
      @include breakpoint(large) {
        margin-left: 100px;
        max-width: 528px;
      }
    }

    &__vector {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.full-img {
  position: relative;
  &__hero {
    position: relative;
    z-index: 10;
    width: 100%;
  }
  &__vector {
    position: absolute;
    right: 0;
    bottom: 86%;
    z-index: 1;
    width: 29%;
  }
}

.location {
  background: #20194b ;
  position: relative;
  padding: 40px 0;
  @include breakpoint(large) {
    padding: 80px 0;
  }

  &__vector {
    position: absolute;
    top: -120px;
    right: 13%;
    z-index: 10;
    @include breakpoint(small only) {
      display: none;
    }
  }

  &__map {
    position: relative;
    z-index: 20;
    padding: 0 0 60px;
    display: flex;
    align-items: center;
    gap: 40px;
    @include breakpoint(small only) {
      flex-direction: column;
    }
    img {
      flex: 0 0 auto;
    }
    &-details {
      @include breakpoint(large) {
        max-width: 528px;
      }
      padding: 0 24px;
      color: $white;
      h3 {
        font-size: 40px;
        font-weight: bold;
        margin: 0;
      }

      h4 {
        font-size: 30px;
      }

      .flag {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 24px;
      }
    }
  }

  &__imgs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 24px;
    @include breakpoint(large) {
      padding: 0 150px;
      flex-direction: row;
      gap: 40px;
    }
  }
}


.location-map{
  background:#20194b;
  color:#ffffff;
  padding:0 100px;
  @include breakpoint(small only){
    padding:0 40px;
  }
  
  h2{
    font-size: 70px;
    color: #ffffff;
    font-weight: bold;
    line-height: 1;
    margin-bottom:32px;
    
    @include breakpoint(small only){
      text-align: center;
    }
  }

  &__area{
      color:#6dbeb6;
  } 

  h4{
    font-size:24px;
    font-weight: normal;
    margin-bottom:32px;
    
    @include breakpoint(medium down){
      font-size:16px;
      margin-bottom:16px;
    }
    strong{
      display: block;
      font-weight: bold;
    }
    a{
      color:#ffffff;
      text-decoration: none;
      margin-top:24px;
      display: block;
      @include breakpoint(medium down){
        margin-top:16px;
      }
    }
  }

  &__content{
    display: flex;
    justify-content: space-between;
    gap:24px;
    @include breakpoint(medium down){
      justify-content: start;
      gap:16px;
      flex-wrap: wrap;
    }
  }

  &__details{
    width: 370px;
    flex:none;
    @include breakpoint(small only){
      width: calc(50% - 8px);
    }
  }

  &__img{
    @include breakpoint(medium down){
      order: -10;
    }
  }
}