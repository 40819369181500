.main-footer {
  color: #a1a1a1;
  background: $black;
  padding: 0 24px;
  @media (min-width: 1460px) {
    padding: 0 100px;
  }

  &__top {
    display: flex;
    gap: 40px;
    padding: 40px 0;
    align-items: center;
    > * {
      flex: 1;
      @include breakpoint(small only) {
        flex: none;
      }
    }

    @include breakpoint(small only) {
      flex-direction: column;
    }
  }

  &__bottom {
    padding: 20px;
    border-top: 1px solid #29292b;
  }

  .logo {
    @include breakpoint(small only) {
      text-align: center;
    }
    img {
      @include breakpoint(small only) {
        width: 150px;
      }
    }
  }

  .social {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      gap: 20px;
      @include breakpoint(small only) {
        justify-content: center;
      }
      li {
        display: block;
        a {
          transition: all ease-out 300ms;
          &:hover {
            opacity: 0.8;
            transition: all ease-out 300ms;
          }
        }
      }
    }
  }

  .footer-links {
    ul {
      margin: 0;
      padding: 0;
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: end;
      li {
        display: block;
        @include breakpoint(small only) {
          text-align: center;
        }
        a {
          color: #a1a1a1;
          text-decoration: none;
          transition: all ease-out 300ms;
          &:hover {
            color: $primary-color;
            transition: all ease-out 300ms;
          }
          .jpn & {
        font-family: "Noto Serif JP";
      }
        }
      }
    }
  }

  .footer-subscribe {
    p {
      margin: 0 0 16px;
    }

    input[type="text"],
    input[type="email"] {
      display: block;
      border: 0;
      color: #a1a1a1;
      font-size: 16px;
      border-radius: 8px;
      background: $white;
      flex: none;
      margin-bottom: 20px;
      width: 100%;
      padding: 8px 12px;
      @include breakpoint(medium) {
        width: 293px;
      }
    }
    input[type="submit"] {
      border-radius: 8px;
      background: $primary-color;
      padding: 8px 12px;
      font-size: 16px;
      border: 0;
      color: $white;
      width: 135px;
      cursor: pointer;
      transition: all ease-out 300ms;
      &:hover {
        opacity: 0.8;
        transition: all ease-out 300ms;
      }

      @include breakpoint(small only) {
        width: 100%;
      }
    }
  }

  

  .copyright {
    text-align: center;
  }
}
