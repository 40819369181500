

.manga-container{
    background: #704590;
    color:#ffffff;
    @include breakpoint(small only) {
        padding: 60px 40px 0;
    }
    @include breakpoint(medium) {
        padding: 100px 100px 0;
    }
}
.manga-list {
    &__items {
        display: flex;
        gap: 40px;
        flex-direction: row-reverse;
        @include breakpoint(small only) {
            flex-direction: column;
            gap:32px;
        }
    }

    &__item {
        flex:1;
       
        @include breakpoint(medium) {
            max-width: 320px;
        }

        a {
            display: block;
            color: #ffffff;
            text-decoration: none;
            transition: 300ms all ease-in-out;

            &:hover {
                color: $primary-color;
            }
        }

        img {
            margin-bottom: 16px;
            box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
            display: block;
            transition: 300ms all ease-in-out;
            opacity: 1;
            &:hover{
               opacity: .8;
                transition: 300ms all ease-in-out;
            }
        }

        h3 {
            font-size: 30px;
            text-align: center;
            display: block
        }
    }
}