.main-container {
  background: #704590;
  color: #ffffff;
  @include breakpoint(small only) {
    padding: 92px 40px 60px;
  }
  @include breakpoint(medium) {
    padding: 143px 100px 60px;
  }
  @media (min-width: 1460px) {
    padding: 200px 100px 60px;
  }
}

p {
  margin: 0 0 24px;
  a {
    color: #ffffff;
  }
}
h2,
h3 {
  margin: 0 0 24px;
}

.submission-policy {
  p {
    font-size: 20px;
    @include breakpoint(small only) {
      font-size: 18px;
    }
  }
  ol {
    margin: 0 0 24px;
  }
  ol li {
    font-size: 20px;
    margin: 0 0 10px;
    @include breakpoint(small only) {
      font-size: 18px;
    }
  }
}

.contact-us {
  form {
    background: #20194b;
    padding: 24px;
    max-width: 600px;

    .form-cols {
      display: flex;
      gap: 32px;
      @include breakpoint(small only) {
        flex-direction: column;
        gap: 0;
      }
      > div {
        flex: 1;
      }
    }
    .form-field {
      margin: 0 0 16px;
      label {
        display: block;
        font-weight: bold;
        font-size: 18px;
      }
      input {
        display: block;
        width: 100%;
        padding: 8px 12px;
        font-size: 18px;
      }
      textarea {
        width: 100%;
        min-height: 200px;
        padding: 8px 12px;
        font-size: 18px;
        @include breakpoint(small only) {
          min-height: 100px;
        }
      }
    }
    button,
    input[type="submit"] {
      padding: 12px 32px;
      font-size: 18px;
      color: #ffffff;
      background: $primary-color;
      font-weight: bold;
      cursor: pointer;
      transition: 300ms all ease-in-out;
      &:hover {
        opacity: 0.8;
        transition: 300ms all ease-in-out;
      }
    }
  }
}

.inner-container {
  p {
    font-size: 20px;
    @include breakpoint(small only) {
      font-size: 18px;
    }
  }
  ul,
  ol {
    margin: 0 0 24px;
  }
  ul li,
  ol li {
    font-size: 20px;
    margin: 0 0 10px;
    @include breakpoint(small only) {
      font-size: 18px;
    }
  }
}

.video-section {
  background: #704590;
  color: #ffffff;

  @include breakpoint(small only) {
    padding: 60px 40px;
  }
  @include breakpoint(medium) {
    padding: 100px;
  }

  &__content {
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
