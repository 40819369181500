html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h1 {
  font-size: 40px;
  line-height: 1.2;
  @include breakpoint(medium) {
    font-size: 70px;
  }
}

h2 {
  font-size: 32px;
  line-height: 1.2;
  @include breakpoint(medium) {
    font-size: 48px;
  }
}

h3 {
  font-size: 18px;
  line-height: 1.2;
  @include breakpoint(medium) {
    font-size: 35px;
  }
}

strong{
  font-weight: bold;
}

// Japanese Font
.en-ar-font{
  font-family: "Apercu Arabic Pro";
}
.jpn-font{
  font-family: "Noto Serif JP";
}

// Helpers
.link-white{
  text-decoration: none;
  color:#ffffff;
}