.hero {
  position: relative;
  &__img {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__caption {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    padding: 140px 24px 24px;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    max-width: 730px;
    .jpn & {
      max-width: 680px;
      @media (min-width: 1460px) {
        max-width: 800px;
      }
    }
    @media (min-width: 1460px) {
      padding: 195px 85px 50px;
      max-width: 855px;
    }
    a {
      color: #ffffff;
      text-decoration: none;
      &:hover {
        color: $primary-color;
      }
    }
    h1 {
      margin: 0;
      background: rgba(0, 0, 0, 0.7);
      padding: 32px;
      .jpn & {
        font-family: "Noto Serif JP";
      }
    }
  }
}

.manga-hero {
  position: relative;
  &__img {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 0 8%;
    }
  }
  &__caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    position: absolute;
    bottom: 85px;
    right: 0;
    color: $white;
    max-width: 700px;
    .jpn & {
      max-width: 725px;
    }
    @include breakpoint(medium) {
      padding: 0 85px;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
    h2 {
      margin: 0;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.5);
      padding: 24px 24px 16px;
      font-weight: bold;
      font-size: 40px;
      @include breakpoint(medium) {
        font-size: 70px;
      }
      transition: 300ms all ease-in-out;
      &:hover {
        color: $primary-color;
        transition: 300ms all ease-in-out;
      }
      margin-bottom: 0;
    }
    p {
      background: rgba(0, 0, 0, 0.8);
      padding: 12px 24px;
    }
  }
}
