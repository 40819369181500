.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(to bottom, #000000 30%, transparent 100%);

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    padding: 16px 24px;
    @media (min-width: 1460px) {
      padding: 50px 100px;
    }
    
  }

  .logo {
    @include breakpoint(small only) {
      width: 100px;
    }
  }

  .header-utility{
    display:flex;
    gap:16px;
  }

  .lang-switch{
    position: relative;
    &__active{
      color:#ffffff;
      display: flex;
      gap:8px;
      cursor: pointer;
      align-items: center;
      font-weight: bold;
    }
    &__list{
      position: absolute;
      top:100%;
      right:0;
      background:#000000;
      border:1px solid #3c3c3c;
      display: none;
      opacity: 0;
      transform: translateY(-100%);
      transition:300ms all ease-in-out;

      &.expanded{
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition:300ms all ease-in-out;
      }
     
      &-item{
        text-align: center;
        padding:5px 10px;
        a{
          color:#ffffff;
          text-decoration: none;
          font-weight: bold;
          transition:300ms all ease-in-out;
          &:hover{
            color:$primary-color;
            transition:300ms all ease-in-out;
          }

        }
      }
    }
  }

  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-top: 6px solid #ffffff;
  }

  .menu-btn{
    display: flex;
    flex-direction: column;
    gap:6px;
    cursor: pointer;
    &:hover{
      span{
        background:$primary-color;
      transition:300ms all ease-in-out

      }
    }
    span{
      height: 3px;
      width: 32px;
      background:#ffffff;
      display: block;
      transition:300ms all ease-in-out
    }
  }
}

.main-nav {
  position: fixed;
  right:0;
  top:0;
  opacity: 0;
  transform: translateX(100%);
  transition:300ms all ease-in-out;
  background:rgba(0,0,0,0.8);
  height: 100%;
  padding:32px 16px;
  width: 370px;
  @include breakpoint(small only){
    width: 50%;
  }

  &.active{
    opacity: 1;
    transform: translateX(0%);
    transition:300ms all ease-in-out
  }
  ul {
    margin: 0 0 40px;
    padding: 80px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    @include breakpoint(small only){
      gap: 16px;
    }
    position: relative;
    :after{
      content:"";
      display: block;
      position: absolute;
      bottom:0;
      left:50%;
      transform: translateX(-50%);
      width: 80px;
      border-bottom:2px solid #ffffff;
    }
    li {
      display: block;
      a {
        display: block;
        text-align: center;
        font-size: 25px;
        color: $white;
        font-weight: 500;
        text-decoration: none;
        @include breakpoint(medium down) {
          font-size: 20px;
        }
        transition: all ease-out 300ms;
        &:hover {
          color: $primary-color;
          transition: all ease-out 300ms;
        }
        .jpn &{
          font-family: "Noto Serif JP";
        }
      }
      &.active {
        a {
          color: $primary-color;
        }
      }
    }
  }

  .menu-btn{
    color:#ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    transition:300ms all ease-in-out;
    width: 100%;
    .jpn &{
      font-family: "Noto Serif JP";
    }
    &:hover{
      color:$primary-color;
    transition:300ms all ease-in-out;

    }
    div{
      position: relative;
      width: 16px;
      height: 16px;
    }
    span{
      position: absolute;
      top:7px;
      left:0;
      height: 2px;
      width: 16px;
      background:$primary-color;
     &:first-child{
      transform: rotate(45deg);
     }
     &:last-child{
      transform: rotate(-45deg);
     }
    }
  }
}
